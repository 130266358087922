import { singleCoordinates, zoomLevelDefault } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { MapContext } from "helpers/map";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DistanceIconSvg } from "../../../assets/icon_world_zoom.svg";
import styles from "./world-zoom.module.scss";

function WorldZoom() {
	const [context, dispatch] = useContext(AppContext);
	const { map } = useContext(MapContext);

	const [t] = useTranslation();

	const resetZoom = () => {
		dispatch({ type: AppAction.setMapZoom, payload: zoomLevelDefault });
		dispatch({ type: AppAction.setMapCenter, payload: singleCoordinates });

		map.setZoomAndCenter(zoomLevelDefault, zoomLevelDefault);
	};

	return (
		<div
			className={`${styles.zoom}`}
			onClick={resetZoom}
		>
			<div
				className="tooltip"
				data-tip={t(`zoom.tip`)}
			>
				<DistanceIconSvg className={styles.icon} />
			</div>
		</div>
	);
}

export default WorldZoom;
