
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import * as turf from '@turf/turf';
import { getLegend } from "api/layer";
import china from "assets/json/c1.json";
import Icon from "components/icon/icon";
import { AppAction, AppContext } from "helpers/context";
import { MapContext } from "helpers/map";
import { Children, MapLayer } from "helpers/models";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Expander from "../expander/expander";
import styles from "./layerList.module.scss";


let oversea = true;
let chinaGeojson = turf.polygon(china.features[0].geometry.rings);

interface Props {
    inputFilter: string;
}

function Layers(props: Props) {
    const [context, dispatch] = useContext(AppContext);
    const { AMap, map } = useContext(MapContext);
    const [t] = useTranslation();
    const [layerList, setLayerList] = useState<MapLayer[]>(context.layerList || []);
    // const [oversea, setOversea] = useState(false);
    const [updateView, setUpdateView] = useState("false");


    useEffect(() => {
        setLayerList(context.layerList || [])
    }, [context.layerList])

    useEffect(() => {
        map?.on(["complete", "moveend", "zoomend"], () => {
            var center = map.getCenter();
            let point = [center.lng, center.lat];
            let zoom = map.getZoom();
            let isChina = turf.booleanPointInPolygon(point, chinaGeojson)
            if (isChina && oversea && zoom >= 7) {
                oversea = false;
                handleChangLayerData(false)
            } else if ((!isChina || zoom < 7) && !oversea) {
                oversea = true;
                handleChangLayerData(true)
            }
        });
    }, [map, layerList])

    const handleChangLayerData = (is: boolean) => {
        layerList.forEach(item => {
            item.children.map(v => {
                if (v.checked) {
                    if (is) {
                        v.layerObjEn.setOpacity(0.5)
                        v.layerObjCn.setOpacity(0)
                    } else {
                        v.layerObjEn.setOpacity(0)
                        v.layerObjCn.setOpacity(0.5)
                    }
                }
                return v;
            })
        });
    }

    const handleCheckLayer = async (layerInfo: Children) => {
        let layerLength = 0
        layerList.forEach(item => {
            layerLength += item.children.filter(v => v.checked).length
        });

        if (layerLength >= 5 && !layerInfo.checked) {
            dispatch({
                type: AppAction.setSnackBarParams, payload: {
                    title: "limitAlert",
                    titleIcon: "error",
                    titleIconColor: "#fff"
                }
            })
            // dispatch({
            //     type: AppAction.setLayerInfoModal,
            //     payload: {
            //         name: (<div style={{ display: "flex", alignItems: "center" }}><span className="material-icons" style={{ marginRight: "8px" }}>{"info"}</span>{t("layers.limitAlertTitle")} </div>),
            //         description: t("layers.limitAlert")
            //     },
            // });
            return;
        }

        layerInfo.checked = !layerInfo.checked;
        setUpdateView(layerInfo.checked + '')
        if (!layerInfo.checked) {
            setTimeout(() => {
                layerInfo.layerObjEn.setOpacity(0)
                layerInfo.layerObjCn.setOpacity(0)
            }, 500)
            dispatch({ type: AppAction.updateLayerLegend, payload: layerInfo });
        } if (layerInfo.layerObjCn) {
            if (oversea) {
                layerInfo.layerObjEn.setOpacity(0.5)
                layerInfo.layerObjCn.setOpacity(0)
            } else {
                layerInfo.layerObjEn.setOpacity(0)
                layerInfo.layerObjCn.setOpacity(0.5)
            }
            dispatch({ type: AppAction.updateLayerLegend, payload: layerInfo });
        } else {
            // var wms = new AMap.TileLayer({
            // 	tileUrl: `${process.env.REACT_APP_AMAP_LAYER_URL}/risklayer-api/proxy/catnet/tiles/${layerInfo.id}/tile/[z]/[x]/[y]?X-Token=${sessionStorage.getItem("token")}`,
            // 	opacity: 0.5,
            // });
            // layerInfo.layerObj = wms;
            // map.addLayer(wms);
            var layerObjEn = new AMap.TileLayer({
                tileUrl: `${(window as any).amapLayerUrl}/risklayer-api/catnet-cn/tilesEx/${layerInfo.id}/tile/[z]/[x]/[y]?X-Token=${sessionStorage.getItem("token")}&oversea=true`,
                opacity: 0.5,
            });
            var layerObjCn = new AMap.TileLayer({
                tileUrl: `${(window as any).amapLayerUrl}/risklayer-api/catnet-cn/tilesEx/${layerInfo.id}/tile/[z]/[y]/[x]?X-Token=${sessionStorage.getItem("token")}&oversea=false`,
                opacity: 0.5,
            });
            layerInfo.layerObjEn = layerObjEn;
            layerInfo.layerObjCn = layerObjCn;
            map.addLayer(layerObjEn);
            map.addLayer(layerObjCn);
            if (oversea) {
                layerInfo.layerObjCn.setOpacity(0)
            } else {
                layerInfo.layerObjEn.setOpacity(0)
            }
            await getLegend(layerInfo.id).then(async (res: any) => {
                dispatch({ type: AppAction.addLayerLegend, payload: { ...res.data, layerInfo } })
            });
        }
    };

    const toggleLayerInfo = (layerInfo: Children) => {
        dispatch({
            type: AppAction.setLayerInfoModal,
            payload: layerInfo,
        });
    };

    interface DebounceFunction<T extends (...args: any[]) => void> {
        (this: unknown, ...args: Parameters<T>): void;
    }

    //防抖 N秒内重复触发的话只触发最后一次
    function debounce<T extends (...args: any[]) => void>(fn: T, delay: number, immediate = false): DebounceFunction<T> {
        let timer: NodeJS.Timeout | null = null;
        let isInvoke = false;

        const _debounce: DebounceFunction<T> = function (this: unknown, ...args: Parameters<T>): void {
            if (timer) clearTimeout(timer);

            if (immediate && !isInvoke) {
                fn.apply(this, args);
                isInvoke = true;
            } else {
                timer = setTimeout(() => {
                    fn.apply(this, args);
                    isInvoke = false;
                }, delay);
            }
        };

        return _debounce;
    }

    return (
        <div className={styles.perils}>
            {(layerList.length > 0) &&
                layerList.map((layer, index) => (
                    (layer.children.filter(v => (t("layers.item." + v.name).toLowerCase()).indexOf(props.inputFilter.toLowerCase()) > -1).length) > 0 && (
                        <Expander
                            key={index}
                            title={t("layers.group." + layer.name)}
                            count={layer.children.length}
                        >
                            {layer.children.map((item, i) => (
                                (t("layers.item." + item.name).toLowerCase()).indexOf(props.inputFilter.toLowerCase()) > -1 &&
                                (<div
                                    // className={
                                    //   !item.onClick ? styles.checkboxDisabled : styles.checkbox
                                    // }
                                    key={i}
                                    className={styles.checkbox}
                                >
                                    <FormGroup
                                        onChange={() => handleCheckLayer(item)}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={item.checked}
                                                    color="success" />
                                            }
                                            label={(<span className='text-[14px] font-normal'>{t("layers.item." + item.name)}</span>)} />
                                    </FormGroup>
                                    <div
                                        className={styles.icons}
                                        onClick={(evt) => evt.stopPropagation()}
                                    >
                                        <Icon
                                            icon="info"
                                            onClick={() =>
                                                toggleLayerInfo({
                                                    name: t("layers.item." + item.name),
                                                    id: item.id,
                                                    group: item.group,
                                                    category: item.category,
                                                    description: t("layers.description." + item.name),
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                )))}
                        </Expander>)
                ))}
        </div>
    );
}

export default Layers;
