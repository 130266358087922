import { ReactComponent as ExcelSvg } from "assets/icon_excel.svg";
import Button from "components/button/button";
import Modal from "components/modal/modal";
import { AppAction, AppContext } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
// import { v4 as uuidv4 } from "uuid";
import countryArray from "assets/json/country.json";
import { getLanguage } from "helpers/translations";
import styles from "./import-modal.module.scss";

function ImportModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [loading, setLoading] = useState(false);
	const language = getLanguage();

	const close = (): void => {
		dispatch({ type: AppAction.setImportModal, payload: false });
	};

	const hanldeChangeUploadFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setLoading(true);
		const locationSource = context.locationSource;
		if (!locationSource) {
			return;
		}
		console.log(locationSource);
		//latlng address

		// if (!context.errorModal) {
		// 	showErrorModal();
		// 	return;
		// }

		const file = event.target.files?.[0];
		if (!file) {
			return;
		}
		const reader = new FileReader();

		const errorData: { row: number; message: string }[] = [];

		reader.onloadend = (progressEvent) => {
			const arrayBuffer = reader.result;
			const options: XLSX.ParsingOptions = { type: "array" };
			const workbook = XLSX.read(arrayBuffer, options);
			const sheetName = workbook.SheetNames;
			const sheet = workbook.Sheets[sheetName[0]];
			const sheet1 = workbook.Sheets[sheetName[1]];
			const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
			const jsonData1 = XLSX.utils.sheet_to_json(sheet1);
			const headerData = XLSX.utils.sheet_to_json(sheet1, { header: 1, range: 0 })[0];
			console.log("headerData", headerData);
			console.log(jsonData, jsonData1);

			if (!headerData) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "invalidTemplate",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "emptySheet",
					},
				});
				setLoading(false);
				close();
				return;
			}

			if (!Array.isArray(headerData) || headerData.length === 0) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "invalidTemplate",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "invalidText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			const tArray = [t("importLocationSet.id"), t("importLocationSet.name")];
			let flag = true;
			tArray.forEach((item) => {
				if (!headerData.includes(item)) {
					flag = false;
					return;
				}
			});

			if (!flag) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "WrongTemplate",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "WrongTemplateText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			if (!jsonData || jsonData.length === 0) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "emptyTitle",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "emptyText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			const header = jsonData[0];
			if (!header || !Array.isArray(header) || header.length === 0) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "emptyTitle",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "emptyText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			const name = header[1];
			// if (!name) {
			// 	dispatch({
			// 		type: AppAction.setSnackBarParams,
			// 		payload: {
			// 			title: "emptyTitle",
			// 			// text: "ccc",
			// 			titleIconColor: "yellow",
			// 			titleIcon: "warning",
			// 			message: "emptyText",
			// 		},
			// 	});
			// 	// setLoading(false);
			// 	// close();
			// 	// return;
			// }

			if (!Array.isArray(jsonData[0]) || jsonData[0].length === 0) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "emptyTitle",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "emptyText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			const ids = jsonData1.map((e: any) => e[t("importLocationSet.id")]);
			const uniqueIds = Array.from(new Set(ids));
			if (ids.length !== uniqueIds.length) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "duplicateIdTitle",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "duplicateIdText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			// const newUUID = uuidv4();

			const items = jsonData1.map((e: any, i) => {
				const row = i + 2;

				const code = e[t("importLocationSet.id")];
				const name = e[t("importLocationSet.name")];

				if (!code) {
					errorData.push({
						row,
						message: t("importErrorMessage.idError"),
					});
				}

				if (!name) {
					errorData.push({
						row,
						message: t("importErrorMessage.nameError"),
					});
				}

				const latitude = e[t("importLocationSet.latitude")];
				const longitude = e[t("importLocationSet.longitude")];

				const country = e[t("importLocationSet.country")] || "";

				const street = e[t("importLocationSet.street")] || "";
				const place = (e[t("importLocationSet.place")] || "").replace("undefined", "");
				const province = e[t("importLocationSet.province")] || "";
				const zipCode = (e[t("importLocationSet.zipCode")] + "").replace("undefined", "");

				const coordinateSystem = e[t("importLocationSet.coordinateSource")];
				const coordinateSource = locationSource === "latlng" ? "userSupplied" : "geoEncoded";

				if (locationSource === "latlng") {
					if (!coordinateSystem) {
						errorData.push({
							row,
							message: t("importErrorMessage.coordinateError"),
						});
					} else if (coordinateSystem !== "GCJ02" && coordinateSystem !== "WGS84") {
						errorData.push({
							row,
							message: t("importErrorMessage.coordinateRangeError"),
						});
					}

					if (!latitude) {
						errorData.push({
							row,
							message: t("importErrorMessage.latitudeError"),
						});
					} else if (latitude < -90 || latitude > 90) {
						errorData.push({
							row,
							message: t("importErrorMessage.latitudeRangeError"),
						});
					}

					if (!longitude) {
						errorData.push({
							row,
							message: t("importErrorMessage.longitudeError"),
						});
					} else if (longitude < -180 || longitude > 180) {
						errorData.push({
							row,
							message: t("importErrorMessage.longitudeRangeError"),
						});
					}
				} else if (locationSource === "address") {
					if (!country) {
						errorData.push({
							row,
							message: t("importErrorMessage.countryError"),
						});
					}

					if (!street && !place && !province && !zipCode) {
						errorData.push({
							row,
							message: t("importErrorMessage.addressError"),
						});
					}
				}

				let countryISO3 = e[t("importLocationSet.countryISO3")];

				if (!countryISO3 && country) {
					const countryItem = countryArray.filter((item) => item.label.toLowerCase().includes(country.toLowerCase()))[0];
					if (countryItem) {
						countryISO3 = countryItem.iso3;
					}
				}

				const number = (e[t("importLocationSet.number")] + "").replace("undefined", "");

				const county = e[t("importLocationSet.county")];

				// const addressEn = `${name || ""} ${number || ""} ${street || ""} ${country || ""} ${place || ""} ${province || ""}`;
				const addressEn = `${number || ""} ${street || ""} ${place || ""} ${province || ""} ${country || ""}`;

				// const addressCn = `${province || ""}  ${place || ""} ${country || ""} ${street || ""} ${number || ""} ${name || ""}`;
				const addressCn = `${country || ""} ${province || ""}  ${place || ""} ${street || ""} ${number || ""}`;

				const address = (language === "en" ? addressEn : addressCn).replace("undefined", "");

				return {
					id: new Date().getTime() + i + "",
					coordinateSource,
					coordinateSystem,
					country,
					countryISO3,
					county,
					code,
					latitude,
					longitude,
					name,
					number,
					place,
					province,
					street,
					zipCode,
					address,
				};
			});

			let params = {
				// id: newUUID.toString(),
				name,
				items,
			};

			if (errorData.length > 0) {
				dispatch({ type: AppAction.setErrorData, payload: errorData });
				setLoading(false);
				showErrorModal();
				return;
			}

			dispatch({ type: AppAction.setCurrentMultipleLocations, payload: params });
			dispatch({ type: AppAction.setEditModal, payload: true });
			setLoading(false);
			close();
		};

		reader.readAsArrayBuffer(file);
	};

	const showErrorModal = (): void => {
		dispatch({ type: AppAction.setErrorModal, payload: true });
	};

	const downloadTip = () => {
		// console.log("downloadTip");

		dispatch({
			type: AppAction.setSnackBarParams,
			payload: {
				title: "downloadTitle",
				// text: "ccc",
				titleIconColor: "white",
				titleIcon: "error",
				message: "downloadText",
			},
		});
	};

	return (
		<Modal
			header={t("locations.modal.title")}
			opened={context.importModal}
			onClose={close}
		>
			<div className={styles.modal}>
				<input
					className={styles.uploadIpt}
					onClick={(e) => e.stopPropagation()}
					onChange={hanldeChangeUploadFile}
					type="file"
				/>
				<div className={styles.import}>
					<div className={styles.upload}>
						<ExcelSvg className={styles.icon} />
						<div className={styles.text1}>{t("locations.modal.text1")}</div>
						<div className={styles.text2}>
							<div>{t("locations.modal.text2")}</div>
							&nbsp;
							<div style={{ color: "green" }}>{t("locations.modal.text3")}</div>
							&nbsp;
							<div>{t("locations.modal.text4")}</div>
							&nbsp;
						</div>
					</div>
					{loading && (
						<div
							className="loading loading-spinner"
							style={{ color: "#666" }}
						></div>
					)}
					<div className={styles.hint}>{t("locations.modal.hint")}</div>
					{/* href={context.language === 'en' ? "/location-set-import-template.xlsx" : "/地点集导入模版.xlsx"} */}
					<a
						className={styles.link}
						onClick={downloadTip}
						href={`${process.env.PUBLIC_URL}/${context.language === "en" ? "location-set-import-template.xlsx" : "地点集导入模版.xlsx"}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{t("locations.modal.link")}
					</a>
				</div>
			</div>
			<div className={styles.buttons}>
				<Button onClick={close}>{t("locations.modal.cancel")}</Button>
			</div>
		</Modal>
	);
}

export default ImportModal;
