import { Badge } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { addLocationsets, getLocationsetsItems } from "api/layer";
import { ReactComponent as EmptyIcon } from "assets/icon_address_emty.svg";
import countryArray from "assets/json/country.json";
import Icon from "components/icon/icon";
import Modal from "components/modal/modal";
import { AppAction, AppContext, getMultipleLocations } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddressEncodingTable from "./address-encoding-table/address-encoding-table";
import styles from "./address-encoding.module.scss";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ height: "calc(100% - 100px)" }}
		>
			{value === index && <Box sx={{ p: 3, height: "100%" }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function AddressEncoding() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const close = (): void => {
		dispatch({ type: AppAction.setAddressEncoding, payload: false });
	};

	const [value, setValue] = useState(0);

	const [candidatesData, setCandidatesData] = useState([] as any[]);
	const [noMatchData, setNoMatchData] = useState([] as any[]);
	const [matchData, setMatchData] = useState([] as any[]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const back = () => {
		close();
		dispatch({ type: AppAction.setEditModal, payload: true });
	};

	useEffect(() => {
		const addressEncoding = context.addressEncoding;

		if (!addressEncoding) {
			return;
		}

		const addressEncodingData = context.addressEncodingData;

		if (!addressEncodingData) {
			return;
		}

		const currentMultipleLocations = context.currentMultipleLocations;

		if (!currentMultipleLocations) {
			return;
		}

		const items = currentMultipleLocations.items;

		if (!items || items.length === 0) {
			return;
		}

		const candidates: any[] = [];
		const noMatch: any[] = [];
		const match: any[] = [];

		const addressEncodingDataWithItems =
			items &&
			items.map((item) => {
				const id = item.id;

				const data = addressEncodingData.find((e) => e.id === id);

				if (!data) {
					return item;
				}

				const geocode = data.geocode;
				const latitude = data.latitude;
				const longitude = data.longitude;
				const quality = data.quality;
				const oversea = data.oversea;
				const spatial = data.spatial;
				const coordinateSystem = spatial === "gcj" ? "gcj02" : "wgs84";
				const coordinateSource = "geoEncoded";

				if (!geocode) {
					return {
						...item,
						latitude,
						longitude,
						quality,
						oversea,
						coordinateSource,
						coordinateSystem,
					};
				}

				const city = Array.isArray(geocode.city) && geocode.city.length === 0 ? "" : geocode.city;
				const country = Array.isArray(geocode.country) && geocode.country.length === 0 ? "" : geocode.country;
				const district = Array.isArray(geocode.district) && geocode.district.length === 0 ? "" : geocode.district;
				const address = (Array.isArray(geocode.formatted_address) && geocode.formatted_address.length === 0 ? "" : geocode.formatted_address).replace("undefined", "");
				const location = Array.isArray(geocode.location) && geocode.location.length === 0 ? "" : geocode.location;
				const number = Array.isArray(geocode.number) && geocode.number.length === 0 ? "" : geocode.number;
				const province = Array.isArray(geocode.province) && geocode.province.length === 0 ? "" : geocode.province;
				const street = Array.isArray(geocode.street) && geocode.street.length === 0 ? "" : geocode.street;
				const county = district;
				const place = city;
				const zipCode = Array.isArray(geocode.postcode) && geocode.postcode.length === 0 ? "" : geocode.postcode;
				const countryISO3 = countryArray.find((item) => item.label.toLowerCase().includes(country.toLowerCase()))?.iso3;

				return {
					...item,
					coordinateSource,
					country,
					countryISO3,
					county,
					id,
					latitude,
					longitude,
					number,
					place,
					province,
					street,
					zipCode,
					quality,
					address,
					oversea,
					city,
					district,
					location,
				};
			});

		console.log(addressEncodingDataWithItems);

		if (!addressEncodingDataWithItems || addressEncodingDataWithItems.length === 0) {
			return;
		}

		addressEncodingDataWithItems.forEach((item) => {
			if (!item) {
				return;
			}
			if (item.quality === 0) {
				noMatch.push(item);
				return;
			}
			if (item.quality >= 4) {
				match.push(item);
				return;
			}
			candidates.push(item);
		});

		setCandidatesData(candidates);
		setNoMatchData(noMatch);
		setMatchData(match);
	}, [context.addressEncodingData]);

	const handleMove = (id: string) => {
		if (!id) {
			return;
		}

		const matchArray = matchData.filter((item) => item.id === id);
		if (!matchArray || matchArray.length === 0) {
			return;
		}

		const matchItem = matchArray[0];
		setNoMatchData([...noMatchData, matchItem]);

		const matchDataCopy1 = matchData.filter((item) => item.id !== id);
		setMatchData(matchDataCopy1);

		dispatch({
			type: AppAction.setSnackBarParams,
			payload: {
				title: "moveMatchTitle",
				titleIconColor: "yellow",
				// titleIcon: "success",
				// message: "moveText",
			},
		});
	};

	const handleNoMatch = (id: string, type?: string) => {
		if (!type) {
			handleSkip(id);
			return;
		}

		if (!id) {
			return;
		}

		const noMatchDataCopy = [...noMatchData];
		const noMatchDataItem = noMatchDataCopy.filter((item) => item.id === id)[0];

		setMatchData([...matchData, noMatchDataItem]);
		setNoMatchData(noMatchDataCopy.filter((item) => item.id !== id));

		dispatch({
			type: AppAction.setSnackBarParams,
			payload: {
				title: "moveNoMatchCompleteTitle",
				titleIconColor: "green",
				// titleIcon: "success",
				// message: "moveText",
			},
		});
	};

	const handleSkip = (id: string) => {
		if (!id) {
			return;
		}

		setNoMatchData(noMatchData.filter((item) => item.id !== id));

		dispatch({
			type: AppAction.setSnackBarParams,
			payload: {
				title: "moveSkipTitle",
				titleIconColor: "red",
				// titleIcon: "success",
				// message: "moveText",
			},
		});
	};

	const handleSelect = (id: string) => {
		if (!id) {
			return;
		}
		const candidatesDataCopy = [...candidatesData];
		const candidatesItem = candidatesDataCopy.filter((item) => item.id === id)[0];
		setMatchData([...matchData, candidatesItem]);
		setCandidatesData(candidatesDataCopy.filter((item) => item.id !== id));

		dispatch({
			type: AppAction.setSnackBarParams,
			payload: {
				title: "moveCandidateTitle",
				titleIconColor: "green",
				// titleIcon: "success",
				// message: "moveText",
			},
		});
	};

	const complete = () => {
		const param = {
			name: context.currentMultipleLocations?.name,
			items: matchData,
		};

		addLocationsets(param)
			.then(async (result) => {
				let isJson = true;
				if (typeof result !== "object") {
					try {
						result = JSON.parse(result);
					} catch (e) {
						console.error(e);
						isJson = false;
					}
				}
				if (!result || !result.data || !result.data.id || !result.status || !isJson) {
					dispatch({
						type: AppAction.setSnackBarParams,
						payload: {
							titleIconColor: "yellow",
							titleIcon: "warning",
							title: "errorTitle",
							text: param.name,
							message: "errorMessage",
						},
					});
					close();
					return;
				}

				const locationSetId = result.data.id;
				console.log(locationSetId);
				getLocationsetsItems(locationSetId).then(async (res) => {
					dispatch({ type: AppAction.setCurrentMultipleLocations, payload: res.data });
					getMultipleLocations(dispatch).then(() => {
						dispatch({ type: AppAction.setSingleLocation, payload: undefined });
						dispatch({ type: AppAction.setSingleLocationAnalysed, payload: undefined });
						close();

						dispatch({
							type: AppAction.setSnackBarParams,
							payload: {
								title: "importSuccessTitle",
								// text: "ccc",
								titleIconColor: "green",
								// titleIcon: "success",
								message: "importSuccessText",
							},
						});
					});
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						titleIconColor: "yellow",
						titleIcon: "warning",
						title: "errorTitle",
						text: param.name,
						message: "errorMessage",
					},
				});
				close();
			});
	};

	return (
		<Modal
			header={t("addressEncoding.title")}
			opened={context.addressEncoding}
			onClose={close}
		>
			<div className={styles.modal}>
				{context.addressEncodingLoading ? (
					<Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<LinearProgress
							color="success"
							sx={{ width: "100%" }}
						/>
						{context.addressEncodingLoadingText}
					</Box>
				) : (
					<Box sx={{ width: "100%", height: "100%" }}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="basic tabs example"
								className={styles.tabs}
								textColor="inherit"
								sx={{ display: "flex", justifyContent: "flex-start", width: "60%" }}
							>
								<Tab
									label={
										<Badge
											badgeContent={candidatesData.length}
											color={value === 0 ? "error" : "default"}
											// sx={{ backgroundColor: 'grey.500', color: 'white' }}
										>
											<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
												<Icon icon="help"></Icon>
												{t("addressEncoding.tab.candidates")}
											</div>
										</Badge>
									}
									{...a11yProps(0)}
									sx={{ flex: 1 }}
								/>
								<Tab
									label={
										<Badge
											badgeContent={noMatchData.length}
											color={value === 1 ? "error" : "default"}
										>
											<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
												<Icon icon="report_problem"></Icon>
												{t("addressEncoding.tab.no_match")}
											</div>
										</Badge>
									}
									{...a11yProps(1)}
									sx={{ flex: 1 }}
								/>
								<Tab
									label={
										<Badge
											badgeContent={matchData.length}
											color={value === 2 ? "error" : "default"}
										>
											<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
												<Icon icon="check_circle"></Icon>
												{t("addressEncoding.tab.match")}
											</div>
										</Badge>
									}
									{...a11yProps(2)}
									sx={{ flex: 1 }}
								/>
							</Tabs>
						</Box>
						<CustomTabPanel
							value={value}
							index={0}
						>
							{!candidatesData || candidatesData.length === 0 ? (
								<div className={styles.empty}>
									<EmptyIcon></EmptyIcon>
									<div>{t("addressEncoding.emtyData")}</div>
								</div>
							) : (
								<div>
									<div style={{ display: "flex", alignItems: "center" }}>
										{t("addressEncoding.text.candidates")}
										<div
											className="tooltip "
											data-tip={t("addressEncoding.hint.candidates")}
										>
											<Icon icon="info"></Icon>
										</div>
									</div>
									<AddressEncodingTable
										data={candidatesData}
										type="candidates"
										handleMove={handleSelect}
										handleData={setCandidatesData}
									/>
								</div>
							)}
						</CustomTabPanel>
						<CustomTabPanel
							value={value}
							index={1}
						>
							{!noMatchData || noMatchData.length === 0 ? (
								<div className={styles.empty}>
									<EmptyIcon></EmptyIcon>
									<div>{t("addressEncoding.emtyData")}</div>
								</div>
							) : (
								<div>
									<div style={{ display: "flex", alignItems: "center" }}>
										{t("addressEncoding.text.no_match")}
										<div
											className="tooltip "
											data-tip={t("addressEncoding.hint.no_match")}
										>
											<Icon icon="info"></Icon>
										</div>
									</div>
									<AddressEncodingTable
										data={noMatchData}
										type="no_match"
										handleMove={handleNoMatch}
										handleData={setNoMatchData}
										isShowBtn={true}
									/>
								</div>
							)}
						</CustomTabPanel>
						<CustomTabPanel
							value={value}
							index={2}
						>
							{!matchData || matchData.length === 0 ? (
								<div className={styles.empty}>
									<EmptyIcon></EmptyIcon>
									<div>{t("addressEncoding.emtyData")}</div>
								</div>
							) : (
								<div>
									<div style={{ display: "flex", alignItems: "center" }}>
										{t("addressEncoding.text.match")}
										<div
											className="tooltip "
											data-tip={t("addressEncoding.hint.match")}
										>
											<Icon icon="info"></Icon>
										</div>
									</div>
									<AddressEncodingTable
										data={matchData}
										type="match"
										handleMove={handleMove}
										handleData={setMatchData}
									/>
								</div>
							)}
						</CustomTabPanel>
					</Box>
				)}
			</div>
			<div className={styles.buttons}>
				<Button
					color="success"
					onClick={back}
					style={{ marginRight: "30px" }}
				>
					{t("addressEncoding.button.back")}
				</Button>
				<Button
					variant="contained"
					color="success"
					disabled={matchData.length === 0}
					onClick={complete}
				>
					{t("addressEncoding.button.complete")}
				</Button>
			</div>
		</Modal>
	);
}
