import { useState, useEffect, useContext } from "react";
import { Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Toolbar, IconButton, TextField, InputAdornment } from "@mui/material";
import styles from "./user.module.scss";
import UserAddModal from "./user-add-modal/userAddModal";
import { useTranslation } from "react-i18next";
import { getUserData, addUserData, getTenantAllData, deleteUserData, editUserData, getRoleData } from "api/layer"
import { statusBgColor, statusText } from "helpers/helpers";
import StatusDropdown from "./status-dropdown/status-dropdown";
import RoleDropdown from "./role-dropdown/role-dropdown";
import StartTimeDropdown from "./start-time-dropdown/start-time-dropdown";
import EndTimeDropdown from "./end-time-dropdown/end-time-dropdown";
import Modal from "components/modal/modal";
import { AppContext, AppAction } from "helpers/context";
import dayjs from "dayjs";

function User() {
	const [t] = useTranslation();
	const [context, dispatch] = useContext(AppContext);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [userData, setUserData] = useState([]);
	const [tenantData, setTenantData] = useState([]);
	const [roleData, setRoleData] = useState([]);
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteModalData, setDeleteModalData] = useState(false);
	const [currentData, setCurrentData] = useState<any>(undefined);
	const [searchData, setSearchData] = useState<{
		roleIds: any[],
		statuses: string[],
		keyCode: string,
		startTime: any,
		endTime: any,
	}>({
		roleIds: [],
		statuses: [],
		keyCode: "",
		startTime: null,
		endTime: null
	});

	const handleSearch = (params: any) => {
		setSearchData((prevData) => ({ ...prevData, [params.name]: params.value }));
		if ((params.name === "startTime" || params.name === "endTime") && params.value && (params.value.format("YYYY-MM-DD") === "Invalid Date")) {
			return
		}
		setPage(0);
		handleGetUserData({ pageNo: 1 }, { ...searchData, [params.name]: params.value });
	}

	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter') {
			handleSearch({ name: "keyCode", value: event.target.value })
		}
	};

	const handleSave = async (params: any) => {
		setOpen(false);
		let res: any = {}
		if (currentData) {
			res = await editUserData(params);
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams, payload: {
						title: "editUser",
						message: "editUserSuccess",
					}
				})
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "editUser",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return
			}
		} else {
			res = await addUserData(params)
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams, payload: {
						title: "addUser",
						message: "addUserSuccess",
					}
				})
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "addUser",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return
			}
		}
		handleGetUserData();
	};

	const toggleFilters = () => {
		setShowFilters(!showFilters);
	};

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
		handleGetUserData({ pageNo: newPage + 1 });
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		handleGetUserData({ pageNo: 0, pageSize: parseInt(event.target.value, 10) });
	};

	const handleDeleteUserData = (user: any) => {
		deleteUserData({ id: user.id }).then((res: any) => {
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams, payload: {
						title: "deleteUser",
						message: "deleteUserSuccess",
					}
				})
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "deleteUser",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return
			}
			handleChangePage(undefined, 0)
		})
		setCurrentData(undefined)
		setDeleteModalData(false);
	}

	const handleGetUserData = async ({ pageNo = page + 1, pageSize = rowsPerPage } = {}, data = searchData) => {
		setIsLoading(true)
		let params = { ...data };
		if (data.startTime) {
			params.startTime = data.startTime.format("YYYY-MM-DD");
		}
		if (data.endTime) {
			params.endTime = data.endTime.format("YYYY-MM-DD");
		}
		const res: any = await getUserData(params, { pageNo, pageSize })
		setIsLoading(false)
		if (res.code !== 200) return
		setUserData(res.data.rows)
		setTotalCount(res.data.totalCount * 1 || 0)
	}

	const handleGetTenantAllData = async () => {
		const res: any = await getTenantAllData()
		if (res.code !== 200) return
		setTenantData(res.data)
	}

	const handleGetRoleData = async () => {
		const res: any = await getRoleData()
		if (res.code !== 200) return
		setRoleData(res.data)
	}

	useEffect(() => {
		handleGetUserData()
		handleGetTenantAllData()
		handleGetRoleData()
	}, [])

	return (
		<div className={styles.container}>
			<div className={styles.title}>{t("userSystem.user")}</div>
			<Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
				<Button
					variant="contained"
					color="success"
					size="small"
					endIcon={<span className="material-icons">add</span>}
					onClick={() => {
						setCurrentData(undefined);
						setOpen(true);
					}}
				>
					{t("userSystem.addUser")}
				</Button>
				<IconButton
					color="success"
					onClick={toggleFilters}
					style={{
						color: showFilters ? "#2e7d32" : "rgba(0, 0, 0, 0.54)",
						background: showFilters ? "#c1e5ca" : "",
					}}
				>
					<span className="material-icons">filter_list</span>
				</IconButton>
			</Toolbar>
			{showFilters && (
				<Box style={{ display: "flex", alignItems: "center", padding: "8px", backgroundColor: "#f5f5f5", justifyContent: "space-between" }}>
					<Box style={{ display: "flex", flex: 1, alignItems: "center" }}>
						<TextField
							color="success"
							variant="outlined"
							size="small"
							placeholder={t("userSystem.filterByKeyword")}
							onKeyDown={handleKeyDown}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<span className="material-icons">filter_list</span>
									</InputAdornment>
								),
							}}
							sx={{
								"& .MuiOutlinedInput-notchedOutline": {
									borderWidth: "0",
								},
								"& .MuiOutlinedInput-input,& .MuiOutlinedInput-input::placeholder": {
									fontSize: "14px",
								}
							}}
						/>
						<RoleDropdown
							roleData={roleData}
							selectedRoles={searchData.roleIds}
							onSave={(value) => handleSearch({ name: "roleIds", value })} />
						<StatusDropdown
							selectedStatus={searchData.statuses}
							onSave={(value) => handleSearch({ name: "statuses", value })} />
						{/* <StartTimeDropdown
							defaultTime={searchData.startTime}
							onSave={(value) => handleSearch({ name: "startTime", value })}
						/>
						<EndTimeDropdown
							defaultTime={searchData.endTime}
							onSave={(value) => handleSearch({ name: "endTime", value })}
						/> */}
					</Box>
					<IconButton
						onClick={() => setShowFilters(false)}
						aria-label="clear"
						color="default"
					>
						<span className="material-icons">clear</span>
					</IconButton>
				</Box>
			)
			}
			<div style={{ flex: 1, overflow: "auto" }}>
				<Table className={styles.table}
					stickyHeader
					aria-label="sticky  table"
					size="small">
					<TableHead>
						<TableRow>
							<TableCell>{t("userSystem.code")}</TableCell>
							<TableCell>{t("userSystem.userAccount")}</TableCell>
							<TableCell>{t("userSystem.owningTenant")}</TableCell>
							<TableCell>{t("userSystem.roleName")}</TableCell>
							<TableCell>{t("userSystem.status")}</TableCell>
							<TableCell>{t("userSystem.startDate")}</TableCell>
							<TableCell>{t("userSystem.endDate")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={8}>
									<div className={styles.loading}>
										<div className="loading loading-spinner loading-lg"></div>
										<div>{t("layers.loading")}</div>
									</div>
								</TableCell>
							</TableRow>
						) : userData.map((user: any, index: number) => (
							<TableRow
								key={index}
								className={styles.row}
							>
								<TableCell>{user.userCode}</TableCell>
								<TableCell>{user.userAccount}</TableCell>
								<TableCell>{user.tenantName}</TableCell>
								<TableCell>{user.roleCodes ? user.roleCodes.split("|").map((roleCode: any) => t("userSystem.roleSelect." + roleCode)).join("|") : ""}</TableCell>
								<TableCell>
									<span className={styles.status} style={{ backgroundColor: statusBgColor(user.status) }}></span>
									{t("userSystem." + statusText(user.status))}
								</TableCell>
								<TableCell>{user.startTime}</TableCell>
								<TableCell>{user.endTime}</TableCell>
								<TableCell style={{ padding: 0 }}>
									<div className={styles.delete}>
										<div
											className={`${styles.icons} delete-set`}
											onClick={(evt) => evt.stopPropagation()}
										>
											<div
												className="tooltip tooltip-bottom"
												data-tip={t("tooltip.edit")}
											>
												<IconButton
													onClick={() => {
														setCurrentData(user);
														setOpen(true);
													}}
												><span className="material-icons">edit</span></IconButton>
											</div>
											<div
												className="tooltip tooltip-bottom"
												data-tip={t("tooltip.delete")}
											>
												<IconButton
													onClick={() => {
														setCurrentData(user);
														setDeleteModalData(true);
													}}
												><span className="material-icons">delete</span></IconButton>
											</div>
										</div>
									</div>
								</TableCell>
							</TableRow>
						))
						}
					</TableBody>
				</Table>
			</div>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={totalCount}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage={t("userSystem.rowsPerPage")}
			/>

			{open && <UserAddModal open={open} editData={currentData} tenantData={tenantData} onClose={() => setOpen(false)} onSave={handleSave} />}
			{deleteModalData && (<Modal
				header={t("userSystem.deleteTitle")}
				opened={deleteModalData}
				onClose={() => setDeleteModalData(false)}
			>
				<div className={styles.info}>
					<div className={styles.text}>{`${t("userSystem.prefix")}${currentData.userAccount}${t("userSystem.suffix")}`}</div>

					<div className="flex justify-end mt-2">
						<Button
							variant="text"
							color="success"
							onClick={() => setDeleteModalData(false)}
							style={{ outline: "none" }}
						>
							{t("deleteLocation.cancel")}
						</Button>
						&nbsp;&nbsp;
						<Button
							variant="contained"
							color="error"
							onClick={() => handleDeleteUserData(currentData)}
						>
							{t("deleteLocation.delete")}
						</Button>
					</div>
				</div>
			</Modal>)
			}
		</div >
	);
}

export default User;
