import { useTranslation } from "react-i18next";
import styles from "./options.module.scss";

export enum Option {
  HAZARD = "hazard",
  CLIMATE = "climate",
}

interface Props {
  selected: Option;
  onChange: (option: Option) => void;
  hazardLayerLength?: number;
  climateLayerLength?: number;
}

function Options(props: Props) {
  const [t] = useTranslation();

  return (
    <div className={styles.options}>
      <div
        className={
          props.selected === Option.HAZARD ? styles.buttonActive : styles.button
        }
        onClick={() => props.onChange(Option.HAZARD)}
      >
        {t("options.hazard")}{props.hazardLayerLength ? `(${props.hazardLayerLength})` : ""}
      </div>
      <div className={styles.divider}></div>
      <div
        className={
          props.selected === Option.CLIMATE
            ? styles.buttonActive
            : styles.button
        }
        onClick={() => props.onChange(Option.CLIMATE)}
      >
        {t("options.climate")}{props.climateLayerLength ? `(${props.climateLayerLength})` : ""}
      </div>
    </div>
  );
}

export default Options;
