import { useState, useEffect, useContext } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Tabs, Tab, Toolbar, IconButton, TextField, Box, InputAdornment } from "@mui/material";
import { styled } from '@mui/material/styles';
import styles from "./login-record.module.scss";
import { ReactComponent as ImportIcon } from "assets/location_set_import.svg";
import { useTranslation } from "react-i18next";
import { getLoginLogData, importRecords, downloadLoginList } from "api/layer"
import { AppContext, AppAction } from "helpers/context";
import StartTimeDropdown from "./start-time-dropdown/start-time-dropdown";
import EndTimeDropdown from "./end-time-dropdown/end-time-dropdown";
import dayjs from "dayjs";

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

function LoginRecord() {
	const { t } = useTranslation();
	const [context, dispatch] = useContext(AppContext);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [logData, setLogData] = useState<any>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [searchData, setSearchData] = useState<{
		keyword: string,
		startLoginTime: any,
		endLoginTime: any,
	}>({
		keyword: "",
		startLoginTime: null,
		endLoginTime: null
	});

	const hanldeDownload = () => {
		downloadLoginList(searchData).then((res: any) => {
			console.log(res);
			const url = window.URL.createObjectURL(new Blob([res as any], { type: "application/force-download;charset=utf-8" }));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${t("userSystem.loginRecordDownload")}.xls`);
			document.body.appendChild(link);
			link.click();
			link.onload = () => {
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			};
		})
	}

	const toggleFilters = () => {
		setShowFilters(!showFilters);
	};

	const handleSearch = (params: any) => {
		setSearchData((prevData) => ({ ...prevData, [params.name]: params.value }));
		if ((params.name === "startLoginTime" || params.name === "endLoginTime") && params.value && (params.value.format("YYYY-MM-DD") === "Invalid Date")) {
			return
		}
		setPage(0);
		handleGetLoginLogData({ pageNo: 1, search: { ...searchData, [params.name]: params.value } });
	}

	const handleKeyDown = (event: any) => {
		if (event.key === "Enter") {
			handleSearch({ name: "keyword", value: event.target.value })
		}
	};

	const handleImportRecords = (event: any) => {
		const file = event.target.files[0];
		if (!file) return;
		const formData = new FormData();
		formData.append('file', file);
		importRecords(formData).then((res: any) => {
			if (res.code === 200) {
				dispatch({
					type: AppAction.setSnackBarParams, payload: {
						title: "importLoginRecord",
						message: "importLoginRecordSuccess",
					}
				})
				handleGetLoginLogData()
			} else {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "importLoginRecord",
						titleIconColor: "yellow",
						titleIcon: "warning",
						resMessage: res.message,
					},
				});
				return
			}
		})
	}

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
		handleGetLoginLogData({ pageNo: newPage + 1 });
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		handleGetLoginLogData({ pageNo: 0, pageSize: parseInt(event.target.value, 10) });
	};

	const handleGetLoginLogData = async ({ pageNo = page + 1, pageSize = rowsPerPage, search = searchData } = {}) => {
		setIsLoading(true)
		let params = { ...search };
		if (search.startLoginTime) {
			params.startLoginTime = search.startLoginTime.format("YYYY-MM-DD");
		}
		if (search.endLoginTime) {
			params.endLoginTime = search.endLoginTime.format("YYYY-MM-DD");
		}
		const res: any = await getLoginLogData({ pageNo, pageSize, ...params })
		setIsLoading(false)
		if (res.code !== 200) return
		setLogData(res.data.rows)
		setTotalCount(res.data.totalCount * 1 || 0)
	}

	useEffect(() => {
		handleGetLoginLogData()
	}, [])

	return (
		<div className={styles.container}>
			<Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
				<div>
					<Button
						variant="contained"
						color="success"
						size="small"
						component="label"
						endIcon={<ImportIcon style={{ width: "14px", height: "14px", fill: "white" }} />}
						style={{ marginRight: "10px" }}
					>
						{t("userSystem.importRecords")}
						<VisuallyHiddenInput
							type="file"
							onChange={handleImportRecords}
						/>
					</Button>
					<Button
						size="small"
						variant="outlined"
						color="success"
						style={{ padding: "4px 10px" }}
						onClick={hanldeDownload}
					>
						{t("single.buttons.run")}
					</Button>
				</div>

				<IconButton
					color="success"
					onClick={toggleFilters}
					style={{ color: showFilters ? "#2e7d32" : "rgba(0, 0, 0, 0.54)" }}
				>
					<span className="material-icons">filter_list</span>
				</IconButton>
			</Toolbar>

			{showFilters && (
				<Box style={{ display: "flex", alignItems: "center", padding: "8px", backgroundColor: "#f5f5f5", justifyContent: "space-between" }}>
					<Box style={{ display: "flex", flex: 1, alignItems: "center" }}>
						<TextField
							color="success"
							variant="outlined"
							size="small"
							placeholder={t("userSystem.filterByKeyword")}
							onKeyDown={handleKeyDown}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<span className="material-icons">filter_list</span>
									</InputAdornment>
								),
							}}
							sx={{
								"& .MuiOutlinedInput-notchedOutline": {
									borderWidth: "0",
								},
								"& .MuiOutlinedInput-input,& .MuiOutlinedInput-input::placeholder": {
									fontSize: "14px",
								}
							}}
						/>
						<StartTimeDropdown
							defaultTime={searchData.startLoginTime}
							onSave={(value) => handleSearch({ name: "startLoginTime", value })}
						/>
						<EndTimeDropdown
							defaultTime={searchData.endLoginTime}
							onSave={(value) => handleSearch({ name: "endLoginTime", value })}
						/>
					</Box>
					<IconButton
						onClick={() => setShowFilters(false)}
						aria-label="clear"
						color="default"
					>
						<span className="material-icons">clear</span>
					</IconButton>
				</Box>
			)}

			<div style={{ flex: 1, overflow: "auto" }}>
				<Table stickyHeader
					aria-label="sticky  table"
					size="small">
					<TableHead>
						<TableRow>
							<TableCell style={{ minWidth: 70 }}>{t("userSystem.index")}</TableCell>
							<TableCell>{t("userSystem.useruuid")}</TableCell>
							<TableCell style={{ minWidth: 200 }}>{t("userSystem.username")}</TableCell>
							<TableCell style={{ minWidth: 100 }}>{t("userSystem.instanceid")}</TableCell>
							<TableCell>{t("userSystem.ip")}</TableCell>
							<TableCell style={{ minWidth: 80 }}>{t("userSystem.country")}</TableCell>
							<TableCell style={{ minWidth: 80 }}>{t("userSystem.region")}</TableCell>
							<TableCell style={{ minWidth: 100 }}>{t("userSystem.logtype")}</TableCell>
							<TableCell style={{ minWidth: 110 }}>{t("userSystem.logintime")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={8}>
									<div className={styles.loading}>
										<div className="loading loading-spinner loading-lg"></div>
										<div>{t("layers.loading")}</div>
									</div>
								</TableCell>
							</TableRow>
						) : logData.map((log: any, index: number) => (
							<TableRow key={log.id}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>{log.userUuid}</TableCell>
								<TableCell>{log.who}</TableCell>
								<TableCell>{log.instanceId}</TableCell>
								<TableCell>{log.ip}</TableCell>
								<TableCell>{log.country}</TableCell>
								<TableCell>{log.province}</TableCell>
								<TableCell>{log.logType}</TableCell>
								<TableCell>{log.loginTime}</TableCell>
							</TableRow>
						))
						}
					</TableBody>
				</Table>
			</div>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={totalCount}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage={t("userSystem.rowsPerPage")}
			/>
		</div>
	);
}

export default LoginRecord;
