import { MapContext } from "helpers/map";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./zooms.module.scss";

export default function Zooms() {
    const { AMap, map } = useContext(MapContext);
    const [t] = useTranslation();

    const handleZoomIn = () => {
        if (map) {
            map.zoomIn();
        }
    }

    const handleZoomOut = () => {
        if (map) {
            map.zoomOut();
        }
    }

    return (<div className={styles.zooms}>
        <div
            onClick={handleZoomIn}
            className="tooltip"
            data-tip={t("zoom.zoomIn")}
            style={{ cursor: "pointer" }}
        >
            <span className={styles.zoomIn}>+</span>
        </div>
        <div
            onClick={handleZoomOut}
            className="tooltip"
            data-tip={t("zoom.zoomOut")}
            style={{ cursor: "pointer" }}
        >
            <span className={styles.zoomOut}>-</span>
        </div>
    </div>);
}
