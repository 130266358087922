import { LeftPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LayersSvg } from "../../assets/icon_layers.svg";
import { ReactComponent as LocationsSvg } from "../../assets/icon_locations.svg";
import { ReactComponent as AdminSvg } from "../../assets/admin.svg";

import styles from "./sidebar.module.scss";

function Sidebar() {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const getClass = (leftPanel: LeftPanel): string =>
    context.leftPanel === leftPanel ? styles.itemActive : styles.item;

  return (
    <div className={styles.sidebar}>
      <div
        className={getClass(LeftPanel.Layers)}
        onClick={() => {
          dispatch({ type: AppAction.setArrowLeft, payload: false });
          setLeftPanel(
            dispatch,
            context.leftPanel === LeftPanel.Layers
              ? LeftPanel.Empty
              : LeftPanel.Layers
          )
        }}
      >
        <div className={styles.icon}>
          <LayersSvg className={styles.image} />
        </div>
        <div className={styles.text}>{t("layers.title")}</div>
      </div>
      <div
        className={
          context.leftPanel &&
            context.leftPanel !== LeftPanel.Layers &&
            context.leftPanel !== LeftPanel.UserSystem
            ? styles.itemActive
            : styles.item
        }
        onClick={() => {
          dispatch({ type: AppAction.setArrowLeft, payload: false });
          setLeftPanel(
            dispatch,
            context.leftPanel &&
              context.leftPanel !== LeftPanel.Layers &&
              context.leftPanel !== LeftPanel.UserSystem
              ? LeftPanel.Empty
              : LeftPanel.Locations
          )
        }}
      >
        <div className={styles.icon}>
          <LocationsSvg className={styles.image} />
        </div>
        <div className={styles.text}>{t("locations.title")}</div>
      </div>
      {context.userRoles && context.userRoles.includes("admin") && (<div
        className={
          context.leftPanel &&
            context.leftPanel === LeftPanel.UserSystem
            ? styles.itemActive
            : styles.item
        }
        onClick={() => {
          dispatch({ type: AppAction.setArrowLeft, payload: false });
          setLeftPanel(
            dispatch,
            context.leftPanel &&
              context.leftPanel === LeftPanel.UserSystem
              ? LeftPanel.Empty
              : LeftPanel.UserSystem
          )
        }}
      >
        <div className={styles.icon}>
          <AdminSvg className={styles.image} />
        </div>
        <div className={styles.text}>{t("userSystem.title")}</div>
      </div>)}
    </div >
  );
}

export default Sidebar;
