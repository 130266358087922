import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Modal from "components/modal/modal";
import { AppAction, AppContext } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./error-modal.module.scss";

function ImportModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	// const [errorData, setErrorData] = useState<{ row: number; message: string }[]>(context.errorData || []);

	const [active, setActive] = useState("rowNumber");
	const [direction, setDirection] = useState<"asc" | "desc">("asc");

	const handleSort = (property: "row" | "message") => {
		setActive(property);
		setDirection(direction === "asc" ? "desc" : "asc");

		context.errorData &&
			context.errorData.sort((a, b) => {
				if (a[property] < b[property]) {
					return direction === "asc" ? 1 : -1;
				}
				if (a[property] > b[property]) {
					return direction === "asc" ? -1 : 1;
				}
				return 0;
			});

		// setErrorData([...errorData]);

		dispatch({ type: AppAction.setErrorData, payload: context.errorData });
	};

	const close = (): void => {
		dispatch({ type: AppAction.setErrorModal, payload: false });
	};

	const cancel = (): void => {
		dispatch({ type: AppAction.setErrorModal, payload: false });
		dispatch({ type: AppAction.setImportModal, payload: false });
	};

	const errorLength = context.errorData?.length;

	// useEffect(() => {
	// 	if (errorData && errorData.length > 0) {
	// 		setErrorData(context.errorData || []);
	// 	}
	// }, [context.errorData]);

	return (
		<Modal
			header={`${t("importErrorModal.titlePrefix")} ${errorLength} ${t("importErrorModal.titleSuffix")}`}
			opened={context.errorModal}
			onClose={close}
		>
			<div className={styles.modal}>
				<div className={styles.info}>
					<p>{t("importErrorModal.info")}</p>
				</div>
				<Paper sx={{ width: "100%", overflow: "auto", boxShadow: "none", flex: 1 }}>
					<Table
						size="small"
						stickyHeader
						aria-label="sticky  table"
					>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: "150px" }}>
									<TableSortLabel
										active={active === "rowNumber"}
										onClick={() => handleSort("row")}
										direction={direction}
									>
										{t("importErrorModal.rowNumber")}
									</TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										active={active === "errorMessage"}
										onClick={() => handleSort("message")}
										direction={direction}
									>
										{t("importErrorModal.errorMessage")}
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{context.errorData &&
								context.errorData.length > 0 &&
								context.errorData.map((item, index) => {
									return (
										<TableRow key={index}>
											<TableCell>{item.row}</TableCell>
											<TableCell>{item.message}</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</Paper>
			</div>
			<div className={styles.buttons}>
				<Button
					color="success"
					onClick={cancel}
				>
					{t("importErrorModal.cancel")}
				</Button>
				<Button
					color="success"
					variant="outlined"
					onClick={close}
				>
					{t("importErrorModal.reuploadFile")}
				</Button>
				<Button
					variant="contained"
					disabled
				>
					{t("importErrorModal.proceed")}
				</Button>
			</div>
		</Modal>
	);
}

export default ImportModal;
