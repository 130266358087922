import { LeftPanel, zoomLevelDefault } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Agenda from "./agenda/agenda";
import MultipleAgenda from "./multipleAgenda/multipleAgenda";
import styles from "./agendas.module.scss";
import { MapLayer, LegendData, AgendasSize } from "helpers/models";

interface Props {
  size: string;
}

function Agendas(props: Props) {
  const [context, dispatch] = useContext(AppContext);
  const [t] = useTranslation();

  const deleteLayer = (item: LegendData) => {
    item.layerInfo.layerObjEn.setOpacity(0)
    item.layerInfo.layerObjCn.setOpacity(0)
    item.layerInfo.checked = false
    dispatch({ type: AppAction.updateLayerLegend, payload: item.layerInfo });
  }

  const agendasClass = () => {
    switch (props.size) {
      case AgendasSize.S:
        return styles.agendasS;
      case AgendasSize.M:
        return styles.agendasM;
      case AgendasSize.L:
        return styles.agendasL;
      default:
        return styles.agendasL;
    }
  };

  return (
    <div
      className={agendasClass()}
    >
      {
        context.currentMultipleLocations && (
          <MultipleAgenda />
        )
      }
      {
        context.layerLegend && (context.layerLegend.map((item, index) => (
          item.layerInfo.checked && (<Agenda
            {...item}
            onClick={() => deleteLayer(item)}
            key={index}
          />)
        )))
      }
    </div>
  );
}

export default Agendas;
