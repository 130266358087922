import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import styles from "./chart.module.scss";

interface ChartProps {
  option: any;
}

const Chart: React.FC<ChartProps> = ({ option }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current!);
    chartInstance.setOption(option);

    return () => {
      chartInstance.dispose(); // 清理资源
    };
  }, [option]);

  return <div ref={chartRef} className={styles.chart}></div>;
};

export default Chart;