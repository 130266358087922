
import Menu from "@mui/material/Menu";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from "@mui/material/IconButton";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';


interface FilterData { province: string[], county: string[], country: string[] }

interface FilterColumnsProps {
    anchorEl: any;
    handleClose: any;
    handleApply: any;
    defaultData: FilterData;
    data: any;
    isActiveType: string;
}

function FilterLocaltios(props: FilterColumnsProps) {
    const [t] = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const open = Boolean(props.anchorEl);
    const [filterData, setFilterData] = useState<FilterData>(props.defaultData);
    const [selectOtions, setSelectOtions] = useState({
        province: {
            isOpen: false,
            options: []
        },
        county: {
            isOpen: false,
            options: []
        },
        country: {
            isOpen: false,
            options: []
        },
    });

    useEffect(() => {
        let province = props.data.map((item: any) => item.province).filter((item: any, index: number, self: any) => self.indexOf(item) === index)
        let county = props.data.map((item: any) => item.county).filter((item: any, index: number, self: any) => self.indexOf(item) === index)
        let country = props.data.map((item: any) => item.country).filter((item: any, index: number, self: any) => self.indexOf(item) === index)

        setSelectOtions({
            province: {
                isOpen: false,
                options: province
            },
            county: {
                isOpen: false,
                options: county
            },
            country: {
                isOpen: false,
                options: country
            },
        })
    }, [props.data])

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFilterData((prevData) => ({ ...prevData, [name]: value }));
        setSelectOtions((prevData) => {
            (prevData as any)[name].isOpen = false;
            return { ...prevData };
        })
    };

    const handleDelete = (index: number, name: any) => {
        if (props.isActiveType) {
            setFilterData((prevData) => ({ ...prevData, [name]: [] }));
        } else {
            let arr = { ...(filterData as any) }[name]
            arr.splice(index, 1)
            setFilterData((prevData) => ({ ...prevData, [name]: arr }));
        }
    };

    const selectEl = (type: string, index: number) => {
        return <FormControl sx={{ marginTop: '8px', marginBottom: '14px', minWidth: 350, maxWidth: 800, display: 'flex' }} key={index}>
            <InputLabel color="success">{t("locations.add." + type)}</InputLabel>
            <Select
                multiple
                color="success"
                name={type}
                value={(filterData as any)[type]}
                open={(selectOtions as any)[type].isOpen}
                onOpen={(event: any) => {
                    if (!(event.target.nodeName === "path" || (event.target.children[0] && event.target.children[0].nodeName === "path"))) {
                        setSelectOtions((prevData) => {
                            (prevData as any)[type].isOpen = true;
                            return { ...prevData };
                        })
                    }
                }}
                onClose={() => setSelectOtions((prevData) => {
                    (prevData as any)[type].isOpen = false;
                    return { ...prevData };
                })}
                onChange={handleChange}
                input={<OutlinedInput label={t("locations.add." + type)} />}
                renderValue={() => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {(filterData as any)[type].map((value: string, index: number) => (
                            <Chip
                                key={value}
                                label={value}
                                onClick={() => { }}
                                onDelete={() => handleDelete(index, type)}
                            />
                        ))}
                    </Box>
                )}
            >
                {(selectOtions as any)[type].options.length > 0 && (selectOtions as any)[type].options.map((value: any) => value && ((filterData as any)[type].includes(value) ? null : (
                    <MenuItem
                        key={value}
                        value={value}
                    >
                        {value}
                    </MenuItem>
                )))}
            </Select>
        </FormControl >
    }

    return <Menu
        anchorEl={props.anchorEl}
        open={open}
        onClose={props.handleClose}
        sx={{
            "& .MuiList-root": {
                py: 0,
            },
        }}
    >
        <div className="card compact dropdown-content p-0 shadow min-w-[380px] rounded-[4px] overflow-hidden">
            <div className="text-[16px] m-[16px] h-[19px] font-medium text-[#000000de]">{t("locationSet.filter")}</div>
            <div className="py-[8px] px-[16px] h-[300px] overflow-y-auto">
                {props.isActiveType ?
                    (selectEl(props.isActiveType, 0))
                    :
                    <Accordion
                        expanded={expanded}
                        sx={{
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            margin: "0!important",
                            '&::before': {
                                display: 'none',
                            }
                        }}>
                        <AccordionSummary
                            expandIcon={<IconButton onClick={() => setExpanded(expanded ? false : true)}>
                                <div className="material-icons">keyboard_arrow_right</div>
                            </IconButton>}
                            sx={{
                                padding: 0,
                                margin: "0!important",
                                minHeight: "34px!important",
                                height: "36px!important",
                                flexDirection: 'row-reverse',
                                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                    transform: 'rotate(90deg)',
                                },
                                '& .MuiAccordionSummary-content': {
                                    margin: "0!important",
                                },
                                '& .MuiTypography-root': {
                                    fontSize: "14px!important",
                                },
                                '&.Mui-expanded,': {
                                    minHeight: "34px"
                                },
                                '& .MuiAccordionSummary-content.Mui-expanded': {
                                    margin: "0!important",
                                }
                            }}>
                            {t("locationSet.geoLocation")}
                        </AccordionSummary>
                        <AccordionDetails sx={{
                            padding: "0!important",
                            width: "100%",
                            '& .MuiTypography-root': {
                                fontSize: "14px!important",
                            },
                        }}>
                            {["province", "country", "county"].map((item, index) => selectEl(item, index))}
                        </AccordionDetails>
                    </Accordion>
                }

            </div>
            <div
                style={{
                    borderTop: '1px solid var(--gray-border)',
                    padding: '16px',
                    height: '64px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    color="success"
                    disabled={props.isActiveType ? (filterData as any)[props.isActiveType].length === 0 : filterData.country.length === 0 && filterData.county.length === 0 && filterData.province.length === 0}
                    onClick={() => {
                        if (props.isActiveType) {
                            setFilterData((prevData) => ({ ...prevData, [props.isActiveType]: [] }));
                        } else {
                            setFilterData({
                                province: [],
                                county: [],
                                country: []
                            })
                        }
                    }}
                >
                    {t("analyseModal.buttons.reset")}
                </Button>
                <div>

                    <Button
                        color="success"
                        style={{ marginRight: '10px' }}
                        onClick={props.handleClose}>
                        {t("analyseModal.buttons.close")}
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => props.handleApply(filterData)}
                    >
                        {t("locationSet.apply")}
                    </Button>
                </div>
            </div>
        </div>
    </Menu >;
}
export default FilterLocaltios;