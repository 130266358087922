import React from "react";
import { Checkbox, TextField, Button, FormControlLabel } from "@mui/material";
import styles from "./role-form.module.scss";

function RoleForm() {
	return (
		<div className={styles.container}>
			<h2>基础版</h2>

			<div className={styles.section}>
				<FormControlLabel
					control={
						<Checkbox
							color="success"
							checked
						/>
					}
					label="风险图层"
				/>
				<FormControlLabel
					control={
						<Checkbox
							color="success"
							checked
						/>
					}
					label="查看"
				/>
			</div>

			<div className={styles.section}>
				<h3>单一地点分析</h3>
				<FormControlLabel
					control={
						<Checkbox
							color="success"
							checked
						/>
					}
					label="自然灾害分析"
				/>
				<FormControlLabel
					control={
						<Checkbox
							color="success"
							checked
						/>
					}
					label="气候风险评分"
				/>
				<FormControlLabel
					control={<Checkbox color="success" />}
					label="分析报告下载"
				/>
			</div>

			<div className={styles.section}>
				<h3>地点集</h3>
				<FormControlLabel
					control={
						<Checkbox
							color="success"
							checked
						/>
					}
					label="创建地点集"
				/>
				<TextField
					color="success"
					label="单个地点集地址数量上限"
					variant="outlined"
					defaultValue="300"
					size="small"
				/>
			</div>

			<div className={styles.section}>
				<FormControlLabel
					control={
						<Checkbox
							color="success"
							checked
						/>
					}
					label="上传地点列表"
				/>
				<FormControlLabel
					control={
						<Checkbox
							color="success"
							checked
						/>
					}
					label="下载地点列表"
				/>
				<FormControlLabel
					control={<Checkbox color="success" />}
					label="自然灾害分析"
				/>
				<FormControlLabel
					control={<Checkbox color="success" />}
					label="气候风险评分"
				/>
				<FormControlLabel
					control={<Checkbox color="success" />}
					label="分析报告下载"
				/>
			</div>

			<div className={styles.section}>
				<FormControlLabel
					control={<Checkbox color="success" />}
					label="管理"
				/>
				<FormControlLabel
					control={<Checkbox color="success" />}
					label="租户管理"
				/>
				<FormControlLabel
					control={<Checkbox color="success" />}
					label="用户管理"
				/>
				<FormControlLabel
					control={<Checkbox color="success" />}
					label="角色管理"
				/>
				<FormControlLabel
					control={<Checkbox color="success" />}
					label="报表"
				/>
			</div>

			<div className={styles.buttons}>
				<Button
					variant="outlined"
					color="success"
				>
					取消
				</Button>
				<Button
					variant="contained"
					color="success"
				>
					保存
				</Button>
			</div>
		</div>
	);
}

export default RoleForm;
