import Button from "components/button/button";
import Icon from "components/icon/icon";
import Input from "components/input/input";
import Modal from "components/modal/modal";
import Results from "components/results/results";
import domtoimage from "dom-to-image";
import { LeftPanel, RightPanel, zoomLevelDefault } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { getLanguage } from "helpers/translations";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { downloadHazardPdf } from "api/layer";
import { ReactComponent as AddLocationSvg } from "assets/icon_add_location.svg";
import { ReactComponent as LocationPinSvg } from "assets/pin_location.svg";
import styles from "./single-location.module.scss";

interface Detail {
	[key: string]: string;
}

interface HailAdditionalDetails {
	"HailHazard-31-year-historical-data-set": {
		"Hail Hazard": string;
		"Hail Days (31 year-average)": string;
		"31-year trend": string;
		"Mean Hail Probability (31-year data)": string;
	};
	"HailHazard-64-year-historical-data-set": {
		"Hail Hazard": string;
		"Hail Days (64 year-average)": string;
		"64-year trend": string;
		"Mean Hail Probability (64-year data)": string;
	};
}

function SingleLocation() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [downloadModal, setDownloadModal] = useState(false);
	const [isLoading, setIsLoading] = useState("0"); //0未开始 1下载中 2下载完成
	const [downloadName, setDownloadName] = useState("");

	const openModal = (): void => {
		dispatch({ type: AppAction.setAnalyseModal, payload: true });
	};

	const handleGoPatchLoaction = () => {
		dispatch({
			type: AppAction.setEditMultipleLocation,
			payload: context.currentMultipleLocationInfo,
		});

		dispatch({
			type: AppAction.setLeftPanel,
			payload: LeftPanel.EditLocation,
		});
	};

	const handleDeleteLoaction = () => {
		dispatch({
			type: AppAction.setDeleteLocationModal,
			payload: context.currentMultipleLocationInfo,
		});
	};

	const closeLocation = (): void => {
		if (context.leftPanel !== LeftPanel.Empty && context.leftPanel !== LeftPanel.Layers && (context.currentMultipleLocations || context.currentMultipleLocationInfo)) {
			goBlack()
		} else {
			dispatch({ type: AppAction.setSingleLocation, payload: undefined });
			dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
			dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Empty });
			dispatch({ type: AppAction.setMapCenter, payload: undefined });
		}
	};

	const dlPdf = async () => {
		if (!context.analysisData || typeof context.analysisData.legendItems === "string" || typeof context.analysisData.analysisItems === "string") {
			return;
		}
		const legendItems = context.analysisData?.legendItems;
		const analysisItems = context.analysisData?.analysisItems;
		setIsLoading("1");
		const keyArray = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P"];
		const param: any = {};

		for (let i = 0; i < analysisItems.length; i++) {
			const e = analysisItems[i];

			let index = 1;

			//layerId
			let key = keyArray[i] + index++;
			let value = e.layerId;
			param[key] = value;

			//label
			key = keyArray[i] + index++;
			value = t("layerId." + e.layerId);
			param[key] = value;

			//legend
			key = keyArray[i] + index++;
			value = await captureScreen(document.getElementById(e.layerId));
			param[key] = value;

			//valueLabel
			key = keyArray[i] + index++;
			value = t("legend." + e.valueLabel);
			param[key] = value;

			key = "_" + key;
			value = t("legend.valueLabel");
			param[key] = value;

			//intensity
			key = keyArray[i] + index++;
			value = !e.intensity || e.intensity === 0 ? 1 : (e.intensity as any);
			param[key] = value;

			key = "_" + key;
			value = t("legend.intensity");
			param[key] = value;

			const details = e.details as Detail;
			if (!details) {
				continue;
			}
			const keysInDetails = Object.keys(details);
			for (let j = 0; j < keysInDetails.length; j++) {
				const k = keysInDetails[j];

				if (k === "Distance to Bush (m)") {
					continue;
				}

				key = keyArray[i] + index++;
				value = details[k];
				let valueFy = t("legend." + value);
				if (!valueFy.startsWith("legend.")) {
					value = valueFy;
				}
				param[key] = value;

				key = "_" + key;
				value = k.replaceAll(":", "").trim();
				valueFy = t("legend." + value);
				if (!valueFy.startsWith("legend.")) {
					value = valueFy;
				}
				param[key] = value;
			}

			const hailAdditionalDetails = e.hailAdditionalDetails as HailAdditionalDetails;
			if (!hailAdditionalDetails) {
				continue;
			}
			console.log(hailAdditionalDetails);
			const hail31 = hailAdditionalDetails["HailHazard-31-year-historical-data-set"];
			const hail64 = hailAdditionalDetails["HailHazard-64-year-historical-data-set"];

			key = keyArray[i] + index++;
			value = hail31["Hail Hazard"];
			let valueFy = t("legend." + value);
			if (!valueFy.startsWith("legend.")) {
				value = valueFy;
			}
			param[key] = value;
			console.log(key, value);

			key = "_" + key;
			value = t("legend.Hail Hazard (31-year historical dataset)");
			param[key] = value;
			console.log(key, value);

			key = keyArray[i] + index++;
			value = hail64["Hail Hazard"];
			valueFy = t("legend." + value);
			if (!valueFy.startsWith("legend.")) {
				value = valueFy;
			}
			param[key] = value;
			console.log(key, value);

			key = "_" + key;
			value = t("legend.Hail Hazard (64-year historical dataset)");
			param[key] = value;
			console.log(key, value);
		}

		param["T2"] = downloadName;
		param["T3"] = t("report.date") + "：" + new Date().toLocaleString();
		param["T4"] = t("report.type");
		param["T5"] = t("report.hazard");
		param["T6"] = t("report.level");
		param["T7"] = t("report.analysis");

		// param["tem"] = "hazard.docx";

		param["lang"] = getLanguage();

		if (getLanguage() === "en") {
			param["tem"] = "hazard.docx";
		} else {
			param["tem"] = "hazard_cn.docx";
		}

		try {
			param["map"] = await captureScreen(document.getElementsByClassName("amap-maps")[0]);
		} catch (error) {
			console.error("capture map error:", error);
		}

		downloadHazardPdf(param)
			.then((response: any) => {
				const url = window.URL.createObjectURL(new Blob([response as any], { type: "application/force-download;charset=utf-8" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `${downloadName}.pdf`);
				document.body.appendChild(link);
				link.click();
				setIsLoading("2");
				link.onload = () => {
					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				};
			})
			.catch((error) => {
				console.error("下载失败:", error);
			});
	};

	const goBlack = () => {
		dispatch({ type: AppAction.setSingleLocation, payload: undefined });
		dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
		dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: undefined });
		dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
		dispatch({ type: AppAction.setMapCenter, payload: undefined });
	};

	const captureScreen = async (dom: any) => {
		return await domtoimage.toPng(dom!);
	};

	const addLocation = () => {
		setLeftPanel(dispatch, LeftPanel.AddLocation);
	};

	return (
		<div className={styles.single}>
			<div>
				<div className={styles.title}>
					<div className="flex items-center">
						{context.leftPanel !== LeftPanel.Empty && context.leftPanel !== LeftPanel.Layers && context.currentMultipleLocations && (
							<Icon
								icon="arrow_back"
								style={{ flexShrink: 0, width: "36px", height: "36px", padding: "6px" }}
								onClick={goBlack}
							/>
						)}
						<div>
							{context.leftPanel !== LeftPanel.Empty && context.leftPanel !== LeftPanel.Layers && context.currentMultipleLocationInfo
								? context.currentMultipleLocationInfo.name
								: context.searchLocationInfo[context.language].formatted_address}
						</div>
					</div>
					<div className="flex items-center">
						{context.leftPanel !== LeftPanel.Empty && context.leftPanel !== LeftPanel.Layers && context.currentMultipleLocationInfo && (
							<div
								className="tooltip z-50"
								data-tip={t("analyseModal.buttons.edit")}
							>
								<Icon
									icon="create"
									onClick={handleGoPatchLoaction}
								/>
							</div>
						)}
						{context.leftPanel !== LeftPanel.Empty && context.leftPanel !== LeftPanel.Layers && context.currentMultipleLocationInfo && (
							<div
								className="tooltip z-50"
								data-tip={t("analyseModal.buttons.delete")}
							>
								<Icon
									icon="delete"
									onClick={handleDeleteLoaction}
								/>
							</div>
						)}
						<div
							className="tooltip z-50"
							data-tip={t("single.addLocation")}
						>
							<div className={styles.icon}>
								<AddLocationSvg onClick={addLocation} />
							</div>
						</div>
						<div>
							<Icon onClick={closeLocation} />
						</div>
					</div>
				</div>
				<div className={styles.info}>
					<div className={styles.image}>
						<LocationPinSvg />
					</div>
					<div className={styles.address}>
						<div>
							<div>
								{context.leftPanel !== LeftPanel.Empty && context.leftPanel !== LeftPanel.Layers && context.currentMultipleLocationInfo
									? context.currentMultipleLocationInfo.place
									: context.searchLocationInfo[context.language].formatted_address}
							</div>
							<div style={{ fontSize: "13px" }}>
								{context.leftPanel !== LeftPanel.Empty && context.leftPanel !== LeftPanel.Layers && context.currentMultipleLocationInfo
									? `${context.currentMultipleLocationInfo.longitudeWgs},${context.currentMultipleLocationInfo.latitudeWgs}`
									: context.searchLocationInfo[context.language]?.location}
							</div>
						</div>

						{context.leftPanel !== LeftPanel.Empty && context.leftPanel !== LeftPanel.Layers && context.currentMultipleLocationInfo ? (
							<div>
								{t("single.province")}
								<span className={styles.province}>{context.currentMultipleLocationInfo.province}</span>
							</div>
						) : (
							context.searchLocationInfo[context.language]?.province && (
								<div>
									{t("single.province")}
									<span className={styles.province}>{context.searchLocationInfo[context.language].province}</span>
								</div>
							)
						)}
					</div>
				</div>
				<Results />
				{/* layers info for a single point is too complex, so disabled for now: {context.singleLocationAnalysed ? <Results /> : <Layers />} */}
			</div>
			<div className={styles.buttons}>
				{context.singleLocationAnalysed && context.analysisData?.legendItems && typeof context.analysisData?.legendItems !== "string" && context.option === "hazard" && (
					<div className="w-fit mr-3">
						<Button
							type="outlined"
							onClick={() => {
								setDownloadModal(true);
								setDownloadName("");
								setIsLoading("0");
							}}
						>
							{t("single.buttonDownload")}
						</Button>
					</div>
				)}
				<Button
					className={styles.buttonAnalyse}
					onClick={openModal}
				>
					{t("single.buttonAnalyse")}
				</Button>
			</div>

			{downloadModal && (
				<Modal
					header={isLoading === "0" ? t("single.downloadTitle") : undefined}
					opened={downloadModal}
					onClose={() => setDownloadModal(false)}
				>
					<div className={styles.analyse}>
						<div className="mt-5 mb-5">
							{isLoading === "0" && (
								<Input
									value={downloadName}
									isClose={true}
									placeholder={t("single.downloadPlaceholder")}
									onChange={(event) => setDownloadName(event.target.value)}
									onClose={() => setDownloadName("")}
								/>
							)}
							{isLoading === "1" && (
								<div className="text-center">
									<div className="loading loading-spinner loading-lg"></div>
									<div>{t("single.downloading")}</div>
								</div>
							)}
							{isLoading === "2" && (
								<div className="text-center">
									<div>{t("single.downloadCompletes")}</div>
								</div>
							)}
						</div>
						<div className="flex justify-between mt-5">
							<div>
								{isLoading === "0" && (
									<Button
										onClick={() => setDownloadName("")}
										type="secondary"
									>
										{t("single.buttons.reset")}
									</Button>
								)}
							</div>
							<div>
								<Button
									onClick={() => setDownloadModal(false)}
									type="secondary"
									className={`${isLoading === "0" ? undefined : styles.buttonClose}`}
								>
									{t("single.buttons.close")}
								</Button>
								{isLoading === "0" && (
									<Button
										disabled={!downloadName}
										onClick={dlPdf}
									>
										{t("single.buttons.run")}
									</Button>
								)}
							</div>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
}

export default SingleLocation;
