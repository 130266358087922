import Modal from "components/modal/modal";

import Options, { Option } from "components/options/options";
import Svg from "components/svg/svg";
import { LeftPanel, RightPanel } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { getCounts, getCheckedCounts } from "helpers/helpers";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LocationsSvg } from "../../assets/icon_locations.svg";
import { getLegends } from "api/layer"
import styles from "./analyse-modal.module.scss";
import SelectLayers from "./select-layers";

function AnalyseModal() {
	const [context, dispatch] = useContext(AppContext);
	const [option, setOption] = useState<Option>(Option.HAZARD);
	const [t] = useTranslation();
	const [hazardButtons, setHazardButtons] = useState<any[]>([]);
	const [climateButtons, setClimateButtons] = useState<any[]>([]);

	const close = (): void => {
		dispatch({ type: AppAction.setAnalyseModal, payload: false });
	};

	useEffect(() => {
		if (context?.layerList?.length) {
			const hazardLegendsIds: string[] = []
			let arr = context?.layerList
				?.map((e) => e.children)
				.flat()
				.map((e) => {
					hazardLegendsIds.push(e.id)
					return {
						id: e.id,
						title: e.name,
						icon: Svg({ icon: e.id }),
					}
				}) || []
			setHazardButtons(arr)
			getLegends({
				layers: hazardLegendsIds.join(","),
			}).then((res: any) => {
				dispatch({ type: AppAction.setHazardLegends, payload: res.data.legends })
			})
		}
	}, [context.layerList])

	useEffect(() => {
		if (context?.crsLayerList?.length) {
			const climateLegendsIds: string[] = []
			let arr = context?.crsLayerList?.map((e) => {
				climateLegendsIds.push(e.id)
				return {
					id: e.id,
					title: e.name,
					icon: Svg({ icon: e.id }),
				}
			}) || [];
            arr = arr.filter(e => e.id !== "wind")
			setClimateButtons(arr)
		}
	}, [context.crsLayerList])


	return (
		<Modal
			header={t("analyseModal.analyseLocations")}
			opened={context.analyseModal}
			onClose={close}
		>
			<div className={styles.analyse}>
				<div>
					<div className={styles.header}>
						<LocationsSvg className={styles.image} />
						<div>
							{context.rightPanel === RightPanel.Single ? (
								<div className="ml-1">{context.leftPanel !== LeftPanel.Empty && context.leftPanel !== LeftPanel.Layers && context.currentMultipleLocationInfo ? context.currentMultipleLocationInfo.name : context.searchLocationInfo[context.language].formatted_address}</div>
							) : (
								context.currentMultipleLocations && <div>
									<div className="ml-3 pb-2">{context.currentMultipleLocations!.name}</div>
									<div className="ml-3">
										{getCheckedCounts(context.currentMultipleLocations!.items)}/{context.currentMultipleLocations?.itemCount}
										{t("analyseModal.locations")}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="mt-5 mb-2">
					<Options
						selected={option}
						onChange={setOption}
					/>
				</div>
				<div className={styles.tabContent}>
					{option === Option.HAZARD ? (
						<SelectLayers
							index={0}
							buttons={hazardButtons}
						/>
					) : (
						<SelectLayers
							index={1}
							buttons={climateButtons}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
}

export default AnalyseModal;
