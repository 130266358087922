import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Toolbar } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { getRoleData, getTenant } from "api/layer";
import { statusBgColor } from "helpers/helpers";
import { getLanguage } from "helpers/translations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteTenantModal from "./delete-tenant-modal/delete-tenant-modal";
import RoleDropdown from "./role-dropdown/role-dropdown";
import SearchInput from "./search-input/search-input";
import StatusDropdown from "./status-dropdown/status-dropdown";
import TenantAddModal from "./tenant-add-modal/tenant-add-modal";
// import TenantDropdown from "./tenant-dropdown/tenant-dropdown";
import styles from "./tenant.module.scss";

interface DeleteTenantModalState {
	tenantId?: string;
	tenantName?: string;
}

const Tenant = () => {
	const [t] = useTranslation();
	const [showFilters, setShowFilters] = useState(false);
	const [open, setOpen] = useState(false);
	const [openDeleteTenantModal, setOpenDeleteTenantModal] = useState<DeleteTenantModalState>({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [tableData, setTableData] = useState([]);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [currentTenant, setCurrentTenant] = useState<any>();
	const [roleData, setRoleData] = useState([]);
	const [roleDropdown, setRoleDropdown] = useState<any[]>([]);
	const [statusDropdown, setStatusDropdown] = useState<any[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [hoveredRow, setHoveredRow] = useState(null);

	const initData = async () => {
		const param: any = {
			tenantType: "",
			keyCode: searchKeyword,
			tenantCode: "",
		};

		const pageParam: any = {
			pageNo: page + 1,
			pageSize: rowsPerPage,
		};

		if (statusDropdown && statusDropdown.length > 0) {
			param.statuses = statusDropdown;
		}

		if (roleDropdown && roleDropdown.length > 0) {
			param.roleIds = roleDropdown.map((role: any) => role.id);
		}

		getTenant(param, pageParam).then((res: any) => {
			if (res.code !== 200 || !res.data || !res.data.rows) {
				return;
			}

			setTableData(res.data.rows);
			setTotalCount(res.data.totalCount * 1 || 0);
		});
	};

	const handleSearch = (keyword: string) => {
		setSearchKeyword(keyword);
		setPage(0);
	};

	const handleStatusDropdown = (value: any) => {
		setStatusDropdown(value);
		setPage(0);
	};

	const handleRoleDropdown = (value: any) => {
		setRoleDropdown(value);
		setPage(0);
	};

	const handleSave = () => {
		setOpen(false);
		initData();
	};

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));

		setPage(0);
	};

	const toggleFilters = () => {
		setShowFilters((prev) => !prev);
	};

	const clearFilters = () => {
		setShowFilters(false);
	};

	const handleGetRoleData = async () => {
		const res: any = await getRoleData();
		if (res.code !== 200) {
			return;
		}
		setRoleData(res.data);
	};

	useEffect(() => {
		initData();
		handleGetRoleData();
	}, []);

	useEffect(() => {
		initData();
	}, [page, rowsPerPage, searchKeyword, roleDropdown, statusDropdown]);

	const getStatusColor = (status: string) => {
		switch (status) {
			case "1":
				return "green";
			case "2":
				return "red";
			case "3":
				return "orange";
			default:
				return "gray";
		}
	};

	const getStatusText = (status: string) => {
		const language = getLanguage();
		if (language === "en") {
			switch (status) {
				case "1":
					return "Activated";
				case "2":
					return "Overdue";
				case "3":
					return "Closed";
				default:
					return "Not Activated";
			}
		}

		switch (status) {
			case "1":
				return "正常";
			case "2":
				return "失效";
			case "3":
				return "停用";
			default:
				return "未激活";
		}
	};

	const tenantNameData = tableData.map((tenant: any) => tenant.tenantName);

	const handleDelete = (id: string, name: string) => {
		setOpenDeleteTenantModal({ tenantId: id, tenantName: name });
	};

	const closeDeleteTenantModal = () => {
		setOpenDeleteTenantModal({});
	};

	return (
		<div className={styles.container}>
			<div className={styles.title}>{t("userSystem.tenant")}</div>

			<Toolbar style={{ justifyContent: "space-between", borderBottom: "1px solid #ccc", marginBottom: "5px", padding: "0" }}>
				<Button
					variant="contained"
					color="success"
					size="small"
					endIcon={<span className="material-icons">add</span>}
					onClick={() => {
						setOpen(true);
						setCurrentTenant(null);
					}}
				>
					{t("userSystem.addTenant")}
				</Button>
				<IconButton
					color="success"
					onClick={toggleFilters}
					style={{
						color: showFilters ? "#2e7d32" : "rgba(0, 0, 0, 0.54)",
						background: showFilters ? "#c1e5ca" : "",
					}}
				>
					<span className="material-icons">filter_list</span>
				</IconButton>
			</Toolbar>

			{showFilters && (
				<Box style={{ display: "flex", alignItems: "center", padding: "8px", backgroundColor: "#f5f5f5", justifyContent: "space-between" }}>
					<Box style={{ display: "flex", flex: 1, alignItems: "center" }}>
						<SearchInput onSearch={handleSearch} />
						{/* <TenantDropdown data={tenantNameData} /> */}
						<RoleDropdown
							roleData={roleData}
							selectedRoles={roleDropdown}
							onSave={(value) => handleRoleDropdown(value)}
						/>
						<StatusDropdown
							selectedStatus={statusDropdown}
							onSave={(value) => handleStatusDropdown(value)}
						/>
					</Box>
					<IconButton
						onClick={clearFilters}
						aria-label="clear"
						color="default"
					>
						<span className="material-icons">clear</span>
					</IconButton>
				</Box>
			)}
			<div style={{ flex: 1, overflow: "auto" }}>
				<Table
					stickyHeader
					aria-label="sticky table"
					size="small"
					className={styles.table}
				>
					<TableHead>
						<TableRow>
							<TableCell>{t("userSystem.code")}</TableCell>
							<TableCell>{t("userSystem.tenantName")}</TableCell>
							<TableCell>{t("userSystem.roleName")}</TableCell>
							<TableCell>{t("userSystem.numberOfUsers")}</TableCell>
							<TableCell>{t("userSystem.status")}</TableCell>
							<TableCell>{t("userSystem.startDate")}</TableCell>
							<TableCell>{t("userSystem.endDate")}</TableCell>
							<TableCell width={120}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableData.map((tenant: any) => (
							<TableRow
								key={tenant.id}
								hover
								onMouseEnter={() => setHoveredRow(tenant.id)}
								onMouseLeave={() => setHoveredRow(null)}
								className={styles.row}
							>
								<TableCell>{tenant.tenantCode}</TableCell>
								<TableCell>{tenant.tenantName}</TableCell>
								{/* <TableCell>{tenant.roleNames}</TableCell> */}
								<TableCell>
									{tenant.roleCodes
										? tenant.roleCodes
												.split("|")
												.map((roleCode: any) => t("userSystem.roleSelect." + roleCode))
												.join("|")
										: ""}
								</TableCell>
								<TableCell>{tenant.existUser || 0 + " / " + tenant.userCount || 0}</TableCell>
								<TableCell>
									<div style={{ display: "flex", alignItems: "center" }}>
										<span
											className={styles.status}
											style={{ backgroundColor: statusBgColor(tenant.status) }}
										></span>
										<span>{getStatusText(tenant.status)}</span>
									</div>
								</TableCell>
								<TableCell>{tenant.startTime}</TableCell>
								<TableCell>{tenant.endTime}</TableCell>
								<TableCell>
									{hoveredRow === tenant.id && (
										<div className={styles.delete}>
											<div
												className={`${styles.icons} delete-set`}
												onClick={(evt) => evt.stopPropagation()}
											>
												<div
													className="tooltip tooltip-bottom"
													data-tip={t("tooltip.edit")}
												>
													<IconButton
														onClick={() => {
															setOpen(true);
															setCurrentTenant(tenant);
														}}
													>
														<span className="material-icons">edit</span>
													</IconButton>
												</div>
												<div
													className="tooltip tooltip-bottom"
													data-tip={t("tooltip.delete")}
												>
													<IconButton onClick={() => handleDelete(tenant.id, tenant.tenantName)}>
														<span className="material-icons">delete</span>
													</IconButton>
												</div>
											</div>
										</div>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<div style={{ marginLeft: "auto" }}>
				<TablePagination
					component="div"
					count={totalCount}
					rowsPerPageOptions={[3, 10, 15, 25]}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={t("userSystem.rowsPerPage")}
					slotProps={{
						select: {
							native: true,
						},
					}}
				/>
			</div>
			{openDeleteTenantModal && openDeleteTenantModal.tenantId && openDeleteTenantModal.tenantName && (
				<DeleteTenantModal
					tenantId={openDeleteTenantModal.tenantId}
					tenantName={openDeleteTenantModal.tenantName}
					initData={initData}
					onClose={closeDeleteTenantModal}
				/>
			)}
			{open && (
				<TenantAddModal
					open={open}
					onClose={() => setOpen(false)}
					onSave={handleSave}
					data={currentTenant}
					roles={roleData}
				/>
			)}
		</div>
	);
};

export default Tenant;
