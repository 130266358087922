import { batchGeoResult, batchGeoStatus } from "api/layer";
import { AppAction } from "helpers/context";

export function pollingFunction(interval: any, batchId: string, dispatch: any, maxAttempts: number = 3) {
	let attempts = 0;

	function makeRequest() {
		const param = {
			batchId,
		};

		attempts++;

		batchGeoStatus(param)
			.then((response) => {
				if (!response || !response.data || !response.data.isCompleted) {
					return;
				}
				clearInterval(intervalId);
				batchGeoResult(param)
					.then((response) => {
						dispatch({ type: AppAction.setAddressEncodingLoading, payload: false });
						if (!response || !response.data || !response.data.batchGeoResults) {
							return;
						}
						dispatch({ type: AppAction.setAddressEncodingData, payload: response.data.batchGeoResults });
					})
					.catch((error) => {
						console.error(error);
					});
			})
			.catch((error) => {
				console.error("请求出错，停止轮询：", error);
				clearInterval(intervalId);
			});
		if (attempts >= maxAttempts) {
			console.warn(`达到最大尝试次数 ${maxAttempts}，停止轮询`);
			clearInterval(intervalId);
		}
	}
	let intervalId = setInterval(makeRequest, interval);
}
