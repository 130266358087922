import styles from "./icon.module.scss";

interface Props {
  icon?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

function Icon(props: Props) {
  return (
    <div className={styles.icon} onClick={props.onClick}>
      <div className="material-icons" style={props.style}>{props.icon || "close"}</div>
    </div>
  );
}

export default Icon;
