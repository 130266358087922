import React from "react";
import { TextField, Button, Box } from "@mui/material";
import styles from "./role-edit.module.scss";

function RoleEdit() {
	return (
		<Box className={styles.container}>
			<h2>编辑</h2>

			<TextField
				label="角色名称"
				variant="outlined"
				defaultValue="高级版"
				fullWidth
				margin="normal"
				color="success"
				required
			/>

			<TextField
				label="角色描述"
				color="success"
				variant="outlined"
				defaultValue="可使用全部功能，单个地点集地址数量可达到10000条"
				fullWidth
				margin="normal"
			/>

			<Box className={styles.buttons}>
				<Button
					variant="outlined"
					color="success"
				>
					取消
				</Button>
				<Button
					variant="contained"
					color="success"
				>
					保存
				</Button>
			</Box>
		</Box>
	);
}

export default RoleEdit;
