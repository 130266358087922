import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from "@mui/material";
import styles from "./role.module.scss";

const roles = [
	{
		name: "基础版",
		description: "使用基础功能，单个地点数量不超过300条",
	},
	{
		name: "高级版",
		description: "使用全部功能，单个地点数量可达到10000条",
	},
	{
		name: "管理版",
		description: "使用账号管理及报表功能",
	},
];

function Role() {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const paginatedRoles = roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	return (
		<div className={styles.container}>
			<h2>角色管理</h2>
			<TableContainer component={Paper}>
				<Table className={styles.table}>
					<TableHead>
						<TableRow>
							<TableCell>角色名称</TableCell>
							<TableCell>描述</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{paginatedRoles.map((role, index) => (
							<TableRow key={index}>
								<TableCell>{role.name}</TableCell>
								<TableCell>{role.description}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={roles.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>
		</div>
	);
}

export default Role;
