import Header from "containers/header/header";
import Sidebar from "containers/sidebar/sidebar";
import { Outlet } from "react-router-dom";
import Dialog from 'components/dialog/dialog'
import { useEffect } from "react";
function MainLayout() {

  //  轮播图暂时不部署 
  // useEffect(() => {
  //   (document.getElementById('my_modal_3') as HTMLDialogElement).showModal()
  // }, []);

  return (
    <div>
      {!(window as any).systemLoading &&
        (<div className="w-full h-full flex justify-center items-center absolute z-[9999] bg-white">
          <div className="next-loading-fusion-reactor">
            <span className="next-loading-dot"></span>
            <span className="next-loading-dot"></span>
            <span className="next-loading-dot"></span>
            <span className="next-loading-dot"></span>
          </div>
        </div>)
      }
      <Header />
      <div className="flex" id="main-content">
        {/* 轮播图暂时不部署 */}
        {/* <Dialog /> */}
        <Sidebar />
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;
