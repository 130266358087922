import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
	onSearch: (keyword: string) => void;
}

const SearchInput: React.FC<Props> = ({ onSearch }) => {
	const [t] = useTranslation();
	const [keyword, setKeyword] = useState("");

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setKeyword(value);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			onSearch(keyword);
		}
	};

	return (
		<TextField
			color="success"
			variant="outlined"
			size="small"
			placeholder={t("userSystem.filterByKeyword")}
			value={keyword}
			onChange={handleChange}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<span className="material-icons">filter_list</span>
					</InputAdornment>
				),
			}}
			sx={{
				"& .MuiOutlinedInput-notchedOutline": {
					borderWidth: "0",
				},
				"& .MuiOutlinedInput-input,& .MuiOutlinedInput-input::placeholder": {
					fontSize: "14px",
				},
			}}
			onKeyDown={handleKeyDown}
		/>
	);
};

export default SearchInput;
